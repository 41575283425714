<template>
  <div>
    <span>View Organization User Summary</span>
  </div>
</template>

<script>
export default {
  name: 'ViewOrganizationUserSummary',
  components: {},
  data() {
    return {
      org_user: {},
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {},
}
</script>

<style lang="scss"></style>
